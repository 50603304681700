/* Usage: var(--secondaryColor) */
:root {
  --primaryColor: #fccbf4;
  --secondaryColor: #13366c;
  --primaryTextColor: #1b1b1b;
  --secondaryTextColor: #ffffff;
  --primaryFont: 'Rubik', sans-serif;
  --secondaryFont: marvin-round, sans-serif;
  --headerFooterColor: #298acd;
  --accent: #61ad98;
  --error: #fe274f;

  
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

* {
  margin: 0;
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
  /* box-sizing: border-box; */
  font-family: var(--primaryFont);
  color: var(--primaryTextColor);
}

h1 {
  font-family: var(--secondaryFont);
}

.app {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  grid-template-rows: 100px auto 60px;
  background-image: url("../img/backgrounds1full.jpg");
  background-position: center;
  background-size:cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@supports (-webkit-touch-callout: none) {
  .app {
      background: url('../img/backgrounds1full.jpg') center top no-repeat scroll;
      background-size: auto 100vh;
      background-color: #6B8B33;
  }    
  }


/* Override the background image for the /powerchamber route */
.app.powerchamber-route {
  background-image: url("../img/backgrounds2.jpg");
}

@supports (-webkit-touch-callout: none) {
  .app.powerchamber-route {
      background: url('../img/backgrounds2.jpg') center top no-repeat scroll;
      background-size: auto 100vh;
      background-color: #afaca5;
  }    
}

.main {
  display: flex;
  max-width: 1500px;
  margin: 0 auto;
}

.footer {
  display: flex;
  grid-column: 1 / 3;
  background-color: var(--headerFooterColor);
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  align-self: flex-end;
  color: var(--secondaryTextColor);
  border-top: 2px solid black;
}

.footer-copyright {
  color: var(--secondaryTextColor);
}

header {
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 2rem 2rem;
  color: var(--primaryTextColor);
}

.power-chamber-connect {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Collapsible {
  background-color: white;
  padding: 20px;
  width: 50%;
  /* border-radius: 10px; */
}

.Collapsible__contentInner {
  padding-top: 20px;
  font-family: var(--primaryFont);
  font-size: 1.2rem;
}


.Collapsible__trigger {
  font-family: var(--secondaryFont);
  font-size: 1.2rem;
  width: 100%;
}

nav {
  display: flex;
}

.img-logo a:hover {
  filter: invert(89%) sepia(11%) saturate(1283%) hue-rotate(280deg) brightness(103%) contrast(98%);
}

nav a {
  margin: 0 1rem;
  color: var(--primaryTextColor);
  text-decoration: none;
  font-family: var(--secondaryFont);
  font-size: 1.5rem;
  align-self: center;
}


.socials {
  margin-left: 1rem;
  display: flex;
}

nav .socials a {
  margin: 0 1rem;
  text-decoration: none;
  font-family: var(--secondaryFont);
  font-size: 1.5rem;
}

nav a:hover {
  color: var(--primaryColor);
}

nav .socials a:hover {
  filter: invert(89%) sepia(11%) saturate(1283%) hue-rotate(280deg) brightness(103%) contrast(98%);
}

nav .selected-nav {
  color: var(--primaryColor);
}

header .socials a img {
  width: 40px;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--primaryTextColor);
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: none;
  font-size: 1.8rem;
  font-family: var(--secondaryFont);
}

.nav-btn-container {
  display: none;

}

.img-logo img {
  margin-top: 20px;
  width: 200px;
}

.content-component {
  padding-left: 20%;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.taac-button-link {
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  text-decoration: none;
  background-color: var(--secondaryColor);
  padding: 20px;
  font-family: var(--secondaryFont);
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 10px;
}

.taac-button-link img {
  height: 30px;
  filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(249deg) brightness(103%) contrast(108%);
}



.taac-button:hover {
  background-color: var(--headerFooterColor);
  color: white;
}

.taac-button img {
  width: 30px;
  filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(249deg) brightness(103%) contrast(108%);
}

.taac-button-container {
  display: flex;
  flex-basis: auto;
  justify-content: center;
}

.content-component-card {
  background-color: #ffffffd4;
  padding: 40px;
  border-radius: 10px;
  border: 2px solid black;
}

.content-img-component {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.content-component-title {
  font-size: 2.2rem;
  font-weight: 800;
  margin-bottom: 20px;
}

.content-component-description {
  font-size: 24px;

  line-height: 1.2;
}

.content-component-subtitle {
  font-size: 24px;
  font-weight: 1000;
  margin-bottom: 20px;

}

.content-img-component img {
  width: 80%;
}

.home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footer a:link {
  text-decoration: none;
  color: var(--secondaryTextColor);
}

.footer a:hover {
  color:var(--primaryColor);
}

.nft-calendar-footer {
  width: 20px;
}

.disabled-link {
  pointer-events: none;
  color: var(--accent);
  cursor: not-allowed;
  opacity: 0.8;
}

.disabled-button {

  color: var(--accent);
  cursor: not-allowed;
  opacity: 0.5;
}




/* Minting Page  */

.cntr {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cntr-text {
  font-size: 32px;

}

.btn-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px !important;
  height: 50px !important;
  background-color: var(--secondaryColor) !important;
  border: 0 !important;
  font-family: var(--secondaryFont);
}

.btn-counter img {
  width: 40px;
  height: 40px;
}

.custom-btn {
  background-color: var(--secondaryColor) !important;
  border: 0 !important;
  padding: 10px 30px;
  padding-bottom: 15px;
  font-size: 1.5rem;
  font-family: var(--secondaryFont);
  color: white;
  width: fit-content;
  align-self: center;
}

.custom-btn:hover, .custom-btn:focus, .custom-btn:active {
  color: #fff;
  background-color: #00b3db;
  border-color: #285e8e; /*set the color you want here*/
}

:not(.btn-check) + .btn:hover, .btn:first-child:hover {
  color: var(--secondaryTextColor);
  background-color: var(--headerFooterColor) !important;

}

.supply-label {
  color: var(--accent);
  font-family: var(--secondaryFont);
  margin-bottom: 10px;
  font-size: 1.7rem;
}

.mint-description {
  font-size: 20px;
  font-weight: 600;
  
}

.mint-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
}

.contract-link {
  margin-top: 30px;
}

.contract-link a {
  text-decoration: none;
  color: var(--headerFooterColor);
}

.mint-content {
  padding-left: 20%;
  font-size: 24px;
  line-height: normal;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;

}

.mint-card {
  background-color: #ffffffd4;
  padding: 40px;
  border-radius: 10px;
  border: 2px solid black;
}

.mint-price-label {
  margin-top: 30px;
}

.mint-content .mint-card h1 {
  margin-bottom: 10px;
  font-size: 2.2rem;
  text-align: center;
}

.mint-connected-wallet-text {
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
  margin-bottom: 15px;
  font-size: 1.2rem;
  background: #ccd4ff;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid black;
}

.mint-connect-wallet {
  display: flex;
  justify-content: center;
}

.alert-error {
  margin-top: 20px;
}

.popup {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
  padding: 20px;
  height: fit-content;
}

.mint-success-link {
  padding-bottom: 20px;
}

.mint-success-link a {
  text-decoration: none;
  color: var(--headerFooterColor);
 
}

.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.not-found-page h1 {
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
}

.power-chamber {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  
}

.power-chamber-card {
  background-color: #ffffffd4;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  /* align-items: center; */
}

.power-chamber-card .mint-connected-wallet-text {
  align-self: center;
}

.power-chamber h1 {
  font-size: 2rem;
}

.power-chamber-h1 {
  margin-bottom: 50px;
}

.power-chamber-description {
  padding-left: 100px;
  padding-right: 100px;
  font-size: 1.3rem;
}

.power-bar {
  width: 200px;
  padding-top: 20px;
  font-family: var(--secondaryFont);
  align-self: center;
}

.progressbar-container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.progressbar-label {
  color: rgba(255, 255, 255, 0);
  font-family: var(--secondaryFont);
  font-size: 30px;

}

.progressbar-component {
  display: flex;
  flex-direction: column;
}

.progress-xp {
  font-family: var(--secondaryFont);
  font-size: 30px;
  padding-top: 20px;
  /* color: var(--); */
}

.progress-level {
  font-family: var(--secondaryFont);
  font-size: 30px;
  padding-bottom: 20px;
}

.aura-img {
  position: absolute;

  height: 60px;
  object-fit: cover;
}

.power-chamber-address-label {
  font-size: 1.4rem;
}

.wlcheck {
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.wlcheck-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffffd4;
  border-radius: 10px;
  padding: 40px;
  border: 2px solid black;
}

.walletStatusLabel {
  font-size: 1.7rem;
  font-family: var(--secondaryFont);
  color: var(--accent);
  margin-bottom: 30px;
  text-align: center;
}

.mint-button-subtitle {
  font-size: 15px;
  margin-top: 10px;
}

.nonWl {
  color: var(--error);
}

.wlcheck h1 {
  text-align: center;
  margin-bottom: 20px;
}

.wlcheck p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.wl-address-form-group {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.wl-alert {
  margin-top: 20px;
}

.faq {
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
}

.faq h1 {
  margin-bottom: 50px;
}

.accordion-header {
  font-family: marvin-round, sans-serif;
}

.faq-accordion {
  width: 50%;
}

.Collapsible {
  border: 1px solid black;
}

.user-page {
  display: flex;
  /* width: 100vh; */
  /* align-items: center; */
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: center;
}

.user-page-header {
  display: flex;
  justify-content: flex-start; /* align items to the left */
  align-items: center;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.user-page-header-title-container {
  margin: 0 auto; /* center element */
  margin-bottom: 20px;
  /* justify-content: center; */
}

.user-page-badge {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}

.user-page h1 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.user-page .mint-connected-wallet-text {
  padding-left: 20px;
  padding-right: 20px;
}

.user-page-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nft-collection {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: space-around;
}

.nft-collection::after {
  content: "";
  flex: auto;
}

@media only screen and (min-width: 1201px) {
  .img-logo-mobile {
    display: none;
  }
}



/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.card_image img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}




.card {
  /* background-color: white; */
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid black;
}

.card_content {
  padding: 1rem;
  background-color: #ffffff48;
  /* background: linear-gradient(to bottom left, #cb09bc 20%, #3c377b 100%); */
}

.card_title {
  color: #000000;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  margin-bottom: 10px;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}

.card_buttons {
  height: 50px;
}

.card_buttons {
  display: flex;
  gap: 10px;
}

.card_buttons a img {
  width: 40px;
  filter: invert(48%) sepia(46%) saturate(4930%) hue-rotate(193deg) brightness(92%) contrast(93%);
}

.card_buttons a img:hover {
  filter: invert(18%) sepia(37%) saturate(2143%) hue-rotate(192deg) brightness(96%) contrast(99%);
}

.card_header {
  display: flex;
  gap: 10px;
}

.card_header .refresh-button img{
  height: 20px;
}

.download-button img:hover {
  cursor: pointer;
  filter: invert(18%) sepia(37%) saturate(2143%) hue-rotate(192deg) brightness(96%) contrast(99%);
}

.download-button img{
  width: 40px;

  filter: invert(48%) sepia(46%) saturate(4930%) hue-rotate(193deg) brightness(92%) contrast(93%);
}

.download-button img:hover {
  cursor: pointer;

  filter: invert(18%) sepia(37%) saturate(2143%) hue-rotate(192deg) brightness(96%) contrast(99%);
}

.portal-canvas {
  width: 500px;
  height: 500px;
  border: 2px solid black;
}




.bg-portal-container h1 {
  font-size: 3rem;
}

.bg-portal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.bg-portal p {
  font-family: var(--secondaryFont);
  font-size: 26px;
}

.bg-portal-container .custom-btn {
  margin-bottom: 40px;
  margin-top: 20px;
}

.portal-select {
  border: 2px solid black;
  padding: 5px;
  font-size: large;
}

.portal-connect-wallet-container {
  display: flex;
  align-items: center;
}

.bg-portal-container .mint-connected-wallet-text {
  margin-bottom: 0px;
}

.portal-select-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.portal-select-container span {
  font-weight: 600;
}

.bg-select-container {
  align-self: flex-start;
  margin-top: 30px;
}

.portal-edit-card {
  display: flex;
  flex-direction: column;
}

.canvas-container {
  position: relative; /* Required to position the overlay on top of the canvas */
}

.loading-overlay {
  position: absolute; /* Required to position the overlay on top of the canvas */
  top: 0;
  left: 0;
  width: 100%; /* Make the overlay the same size as the canvas */
  height: 100%; /* Make the overlay the same size as the canvas */
  background-color: rgba(255, 255, 255, 1); /* Make the overlay semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
}

.level-badge-container {
  display: flex;
  gap: 10px; 
}

.level-batch-component {
  display: flex;
  flex-direction: column;
  background: #ccd4ff;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid black;
  align-self: center;
  margin-bottom: 20px;
}

.level-batch-component h2 {
  font-family: var(--secondaryFont);
}

.level-badge-container img {
  width: 50px;
}

.portal-butoon-logo {
  transition: transform 0.5s;
}

.portal-butoon-logo:hover {
  transform: rotate(180deg);
}

.disabled-img {
  transition: none;
  filter: invert(55%) sepia(4%) saturate(25%) hue-rotate(31deg) brightness(90%) contrast(80%);
  /* pointer-events: none; */

}

.disabled-img:hover {
  transform: none;
}

.power-chamber-card video {
  width: 50%;
  height: auto;
  clip-path: inset(1px 1px);
}

.taac-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 40px;

}

.taac-detail-page h2 {
  font-family: var(--secondaryFont);
}

.taac-detail-card {
  background-color: #9ba9e3;
  border: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 1fr; /* divide the container into two equal columns */
  grid-template-rows: min-content min-content 1fr min-content; /* divide the container into three equal rows */
  margin-bottom: 40px;
  width: 80%;
}

.taac-detail-card-image {
  grid-column: 1; /* span the first column */
  grid-row: 1 / 5; /* span the entire first row */
  display: flex;
}

.taac-detail-card-image img {
  width: 100%;
  margin-left: 0px;
  object-fit: contain;
  align-self: flex-end;
}

.taac-detail-card-header {
  grid-column: 2; /* span the second column */
  grid-row: 1; /* span the first row */
  padding: 20px; 
  grid-auto-rows: min-content; 
  display: flex;
  justify-content: space-between;

}

.taac-detail-card-header h2 {
  font-size: 2.5rem;
}

.taac-detail-card-header img {
  height: 50px;
  width: 50px;
  
}

.taac-detail-card-links-container {
  grid-column: 2; /* span the second column */
  grid-row: 2; /* span the second row */
  width: 100%;
  padding: 0px 20px; 
  grid-auto-rows: min-content; 

}

.detail-traits-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 2; /* span the second column */
  grid-row: 3; /* span the third row */
  padding: 20px; 
}

.detail-clear-chakra-container {
  grid-column: 2; /* span the second column */
  grid-row: 4; /* span the first row */
  display: flex;
  padding: 20px;
  justify-content: center;
}




.detail-items-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 20px;
}

.detail-item {
  display: flex;
  align-items: center;
  /* width: 300px; */
  background-color: #ccd4ff;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  gap: 10px;
  
}


.detail-key {
  font-weight: bold;
  margin-right: 10px;
}

.detail-item-value-container {
  display: flex;
  flex-direction: column;
  
  
}

.detail-item img {
  width:30px;
}

.grayscale-img {
  filter: grayscale(.9);
}

.music-player-component img{
  width: 50px;
}

.clear-chakra-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.clear-chakra-current-xp {
  font-size: 2rem;
}

.clear-chakra-cost {
  font-size: 1.5rem;
}

.clear-chakra-button-container {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}

.clear-chakra-button-container-cleared {
  display: flex;
  align-self: center;
  margin-top: 20px;

}

.clear-chakra-button-container-error {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.taac-detail-page .popup h1 {
  text-align: center;
}

.clear-chakra-img-container {
  display: flex;
  gap: 20px;
}

.clear-chakra-img-container img {
  width: 100px;
}

.all-chakras {
  display: flex;
  gap: 5px;
}


@media (min-width: 600px) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .cards_item {
    width: 33%;
  }
}

@media (min-width: 1300px) {
  .cards_item {
    width: 25%;
  }
}


@media (max-width: 1200px) {
  .detail-items-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .detail-items-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .taac-detail-card-lower-container {
    order: -1;
  }

  .taac-detail-card-upper-container {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .portal-canvas {
    width: 300px;
    height: 300px;
  }

  .portal-connect-wallet-container {
    flex-direction: column;
    gap: 10px;
  }

  
}

@media only screen and (max-width: 1200px) {

  .power-chamber-card video {
    width: 100%;
  }

  .app {
    height: auto;
    grid-template-rows: auto;
    /* background-image: url("../img/backgrounds1mobile.png"); */
    /* background-size: contain; */
  }

  header {
    position: sticky;
    top: 0;
    height: 70px;
    background-color: var(--headerFooterColor);
    z-index: 10;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
    position: inherit;
  }

  .popup_inner {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 10%;
    bottom: 10%;
    margin: auto;
    background: white;
    padding: 20px;
    height: fit-content;
  }

  .nav-btn-container {
    display: flex;
  }
  
  .img-logo-mobile img {
    height: 50px;
  }

  .img-logo img {
    display: none;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--headerFooterColor);
    transition: 0.5s;
    transform: translateY(-125vh);
  }

  header .responsive_nav {
    transform: none;
  }

  header .nav-close-btn {
    display: none;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.7rem;
    font-family: var(--secondaryFont);
    color: var(--secondaryTextColor);
  }

  nav .socials img {
    filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(249deg) brightness(103%) contrast(108%);
  }

  .main {
    margin-top: 100px;
  }

  :not(.btn-check) + .btn:hover, .btn:first-child:hover {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor) !important;
  
  }

  .walletStatusLabel {
    font-size: 1.5rem;
  }

  .not-found-page h1 {
    text-align: center;
    background-color: #ffffffd1;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    border-radius: 10px;
  }

  .footer {
    height: 100px;
    flex-direction: column;
    gap: 5px;
    align-content: center;
    text-align: center;
    justify-content: center;
    color: var(--secondaryTextColor);
    border: 1px solid black;
  }

  .home {
    grid-template-columns: 1fr;
    padding: 20px;
    padding-bottom: 0;
    
  }

  .wl-address-form-group {
    flex-direction: column;
    align-items: inherit;
  }

  .wl-button {
    align-self: center;
  }

  .content-component {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }

  .content-img-component img {
    width: 100%;
}

  .mint-page {
    grid-template-columns: 1fr;
  }

  

  .mint-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .power-chamber-description {
    padding-left: 40px;
    padding-right: 40px;
    padding: 0;
    font-weight: 600;
    font-size: 1rem;
  }

  .Collapsible {
    width: 100%;
  }

  .faq-accordion {
    width: 100%;
  }

  .user-page h1 {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .user-page-badge {
    align-self: center;
    flex-direction: column;
  }

  .level-badge-container img {
    width: 30px;
  }

  .taac-detail-card {
    grid-template-columns: 1fr; /* divide the container into two equal columns */
  }

  .taac-detail-card-image {
    grid-row: 5; /* span the entire first row */
  }

  .taac-detail-card-header {
    grid-row: 1; /* span the first row */
    grid-column: 1;
    flex-direction: column;
  }
  
  .taac-detail-card-links-container {
    grid-row: 2; /* span the second row */
    grid-column: 1;
  }
  
  .detail-traits-container {
    grid-row: 3; /* span the third row */
    grid-column: 1;
  }

  .detail-clear-chakra-container {
    grid-row: 4; /* span the third row */
    grid-column: 1;
  }
  
  .taac-detail-card-header h2 {
    font-size: 1.8rem;
  }

  .taac-detail-card-header img {
    height: 40px;
    width: 40px;
}

.music-player-component img {
  filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(249deg) brightness(103%) contrast(108%);
}

.clear-chakra-img-container img {
  width: 50px;
}

.clear-chakra-button-container {
  flex-direction: column;
  gap: 20px;
}



.clear-chakra-current-xp {
  font-size: 1.5rem;
  text-align: center;
}

.clear-chakra-cost {
  font-size: 1.2rem;
  text-align: center;
}


}